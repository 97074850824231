// number-conversion.pipe.ts
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "numberConversion",
})
export class NumberConversionPipe implements PipeTransform {
	transform(value: string | number, locale: "en" | "bn"): string {
		if (value === null || value === undefined) return "";

		const valueAsString = value.toString();

		if (locale === "bn") {
			return this.convertToBangla(valueAsString);
		} else if (locale === "en") {
			return this.convertToEnglish(valueAsString);
		}
		return valueAsString;
	}

	private convertToBangla(value: string): string {
		const englishToBanglaMap: { [key: string]: string } = {
			"0": "০",
			"1": "১",
			"2": "২",
			"3": "৩",
			"4": "৪",
			"5": "৫",
			"6": "৬",
			"7": "৭",
			"8": "৮",
			"9": "৯",
		};
		return value.replace(/\d/g, (match) => englishToBanglaMap[match]);
	}

	private convertToEnglish(value: string): string {
		const banglaToEnglishMap: { [key: string]: string } = {
			"০": "0",
			"১": "1",
			"২": "2",
			"৩": "3",
			"৪": "4",
			"৫": "5",
			"৬": "6",
			"৭": "7",
			"৮": "8",
			"৯": "9",
		};
		return value.replace(/[০-৯]/g, (match) => banglaToEnglishMap[match]);
	}
}
