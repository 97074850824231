import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "monthNameTransform",
})
export class MonthNameTransformPipe implements PipeTransform {
	transform(value: string | number, locale: "en" | "bn"): string {
		if (value === null || value === undefined) return "";

		const monthNumber = Number(value);

		if (isNaN(monthNumber) || monthNumber < 1 || monthNumber > 12) {
			return locale === "bn" ? "অবৈধ মাস সংখ্যা" : "Invalid month number";
		}

		if (locale === "bn") {
			return this.convertToBanglaMonth(monthNumber);
		} else if (locale === "en") {
			return this.convertToEnglishMonth(monthNumber);
		}

		return value.toString();
	}

	private convertToBanglaMonth(monthNumber: number): string {
		const englishToBanglaMap: { [key: number]: string } = {
			1: "জানুয়ারি",
			2: "ফেব্রুয়ারি",
			3: "মার্চ",
			4: "এপ্রিল",
			5: "মে",
			6: "জুন",
			7: "জুলাই",
			8: "আগস্ট",
			9: "সেপ্টেম্বর",
			10: "অক্টোবর",
			11: "নভেম্বর",
			12: "ডিসেম্বর",
		};
		return englishToBanglaMap[monthNumber];
	}

	private convertToEnglishMonth(monthNumber: number): string {
		const banglaToEnglishMap: { [key: number]: string } = {
			1: "January",
			2: "February",
			3: "March",
			4: "April",
			5: "May",
			6: "June",
			7: "July",
			8: "August",
			9: "September",
			10: "October",
			11: "November",
			12: "December",
		};
		return banglaToEnglishMap[monthNumber];
	}
}
