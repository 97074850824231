import internal from "stream";

export interface CaseListFormModel {
	url: string;
	titleEn: string;
	titleBn: string;
	detailTitleEn: string;
	detailTitleBn: string;
	caseListType: string;
	actionForms:ActionForm[];
}
export class CaseSearchModel {
	primaryCaseTypeId: number;
	officeTypeId: number;
	officeId: number;
	userName: string;
	caseStatus: number;
	caseTypeId: number;
}
export class ActionForm {
	name: string;
	titleEn: string;
	titleBn: string;
	access: string[];
	url: string;
	listType: string[];
}

export interface CaseStatusModel {
	id: number,
	nameEn: string,
	nameBn: string
}