import { CommonModule, DatePipe } from "@angular/common";
import { NgModule } from "@angular/core";
import { CMSDatePipe } from "./cms-date.pipe";
import { MonthNameTransformPipe } from "./month-name-transform.pipe";
import { NumberConversionPipe } from "./number-conversion.pipe";
import { UniqueDataPipe } from "./unique-data.pipe";

@NgModule({
	declarations: [CMSDatePipe, UniqueDataPipe, NumberConversionPipe, MonthNameTransformPipe],
	imports: [CommonModule],
	exports: [CMSDatePipe, UniqueDataPipe, NumberConversionPipe, MonthNameTransformPipe],
	providers: [DatePipe],
})
export class CMSDatePipeModule {}
