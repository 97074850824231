import { Pipe, PipeTransform } from '@angular/core';
import { Injectable } from '@angular/core';

import * as _ from "lodash";


@Pipe({
  name: 'unique',
  pure: false

})
export class UniqueDataPipe implements PipeTransform {

  transform(value: any, uniqueConstraint:any): any {
      return _.uniqBy(value,uniqueConstraint);
  }

}
