declare var google: any;

export class GoogleChartsBaseService {
    constructor() {
        google.charts.load('current', { 'packages': ['corechart'] });
    }

    protected buildChart(data: any[], chartFunc: any, options: any): void {
        var func = (chartFunc:any, options:any) => {
            var datatable = google.visualization.arrayToDataTable(data);
            // var view = new google.visualization.DataView(datatable);
            // view.setColumns([0, 1,
            //                  { calc: "stringify",
            //                    sourceColumn: 0,
            //                    type: "string",
            //                    role: "annotation" },
            //                  2]);
            chartFunc().draw(datatable, options);
        };
        var callback = () => func(chartFunc, options);
        google.charts.setOnLoadCallback(callback);
    }

}